import React from "react";
import RadioBox from "./RadioBox";
import FormLabel from "./FormLabel";
import CheckBox from "./CheckBox";


const ChecklistType = ({rData, onChange}) => {
  switch (parseInt(rData.checkListType)) {
    case 1:
      return (
        <>
          <div className="font-medium text-gray-700">
            {rData.question}
          </div>
          <RadioBox
            dependentOn={true}
            value={rData["optionYesNo"]}
            name={
              "answer_" +
              rData["checkListId"] +
              "_" +
              rData["checkListItemId"]
            }
            onChange={onChange}
            align="justify-center"
            dataStr="1|Yes,2|No"
          />
        </>
      );
    case 2:
      return (
        <CheckBox
          label={rData.question}
          dependentOn={true}
          onChange={onChange}
        />
      )
    case 3:
      return (
        <div>
          <div className="font-medium text-gray-700">
            {rData.question}
          </div>
          <input className="w-full font-normal px-3 py-2.5 rounded-lg focus:outline-none read-only:bg-gray-100 text-black border mt-4" onChange={onChange}/>
        </div>
      );
    default:
      break;
  }
}

const CheckList = ({
  dataStr,
  data,
  dependentOn,
  error,
  label,
  name,
  onChange,
}) => {
  if (dataStr && dataStr !== "") {
    const dataArr = dataStr.split(",");
    data = [];
    dataArr.forEach((dt) => {
      let tempArray = dt.split("|");
      let dataObj = {};
      dataObj.idField = tempArray[0];
      dataObj.valueField = tempArray[1];
      data.push(dataObj);
    });
  }

  const handleChecklist = (evt, rData) => {
    
    switch (parseInt(rData.checkListType)) {
      case 1:
        rData.optionYesNo = evt.target.value;
        rData["answer_" + rData["checkListId"] + "_" + rData["checkListItemId"]] = evt.target.value;
        break;
      case 2:
        rData.optionTick = evt.target.checked;
        rData["answer_" + rData["checkListId"] + "_" + rData["checkListItemId"]] = evt.target.checked;
        break;
      case 3:
        rData.optionNotes = evt.target.value;
        rData["answer_" + rData["checkListId"] + "_" + rData["checkListItemId"]] = evt.target.value;
        break;
    }

    onChange(rData);
  };

  // const containerStyles = {
  //   gridColumn: "span 12",
  //   display: dependentOn ? "block" : "none",
  //   border: error ? "1px solid red" : "none",
  // };

  return (
    <div className="col-span-3">
      <div className="space-y-6">
        {data &&
          data?.map((rData) => {
            return (
              <div>
                <div>
                  <ChecklistType rData={rData} onChange = {(e) => handleChecklist(e, rData)}/>
                  {/* <RadioBox
                    dependentOn={true}
                    value={rData["optionYesNo"]}
                    name={
                      "answer_" +
                      rData["checkListId"] +
                      "_" +
                      rData["checkListItemId"]
                    }
                    onChange={(e) => handleRadioChange(e, rData)}
                    align="justify-center"
                    dataStr="1|Yes,2|No"
                  /> */}
                </div>
              </div>
            );
          })}
      </div>
    </div>
    // <div style={containerStyles}>
    //   <Row className="checklist_group">
    //     {data &&
    //       data?.map((rData) => {
    //         return (
    //           <Row className="flex_w_100">
    //             <Col span={10} className="checklist_label">
    //               {rData.question}
    //             </Col>
    //             <Col>
    //               <RadioBox
    //                 dependentOn={true}
    //                 value={rData["optionYesNo"]}
    //                 name={
    //                   "answer_" +
    //                   rData["checkListId"] +
    //                   "_" +
    //                   rData["checkListItemId"]
    //                 }
    //                 onChange={(e) => handleRadioChange(e, rData)}
    //                 align="justify-center"
    //                 dataStr="1|Yes,2|No"
    //               />
    //             </Col>
    //           </Row>
    //         );
    //       })}
    //   </Row>
    // </div>
  );
};

export default CheckList;
