import React from "react";
import FormLabel from "./FormLabel";
import { useState } from "react";

export default function RadioBox({
  dataStr,
  data,
  dependentOn,
  idField,
  error,
  label,
  name,
  id,
  value = "",
  valueField,
  onChange,
  size,
}) {
  const [rbSelect, setRbSelect] = useState(value);

  if (!id) {
    id = name;
  }

  if (dataStr != "") {
    const dataArr = dataStr.split(",");
    data = [];
    dataArr.forEach((dt) => {
      let tempArray = dt.split("|");
      let dataObj = {};
      dataObj.idField = tempArray[0];
      dataObj.valueField = tempArray[1];
      data.push(dataObj);
    });
  }

  idField = idField || "idField";
  valueField = valueField || "valueField";

  const hadleOnChange = (e) => {
    console.log(e.target);
    onChange(e);
  };

  return (
    <div
      // sm={24}
      // md={size || 24}
      // lg={size || 24}
      // xl={size || 24}
      // xxl={size || 24}
      className={!dependentOn ? "hidden" : "align_center"}
    >
      <FormLabel key={label + 1} text={label} color={error ? "red" : "black"}>
          {data &&
            data?.map((rData) => {
              return (
                <div className="font-medium" key={rData.idField}>
                  <label className="flex items-center gap-2 font-semibold text-sm">
                    <input 
                      type="radio" 
                      value={rData.idField} 
                      // Commented this because are not using this form in edit mode so dont need to make it checked
                      // checked={JSON.parse(rData.idField) == value} 
                      id={name} 
                      name={name} 
                      onChange={hadleOnChange}
                    />
                    {rData.valueField}
                  </label>
                </div>
              );
            })}
      </FormLabel>
    </div>
    // <Col
    //   sm={24}
    //   md={size || 24}
    //   lg={size || 24}
    //   xl={size || 24}
    //   xxl={size || 24}
    //   className={!dependentOn ? "hidden" : "align_center"}
    // >
    //   <FormLabel key={label + 1} text={label} color={error ? "red" : "black"}>
    //     <Radio.Group
    //       className="d_flex items_center f_wrap"
    //       id={name}
    //       name={name}
    //       onChange={hadleOnChange}
    //       // value={JSON.parse(rData.idField)==value}
    //     >
    //       {data &&
    //         data?.map((rData) => {
    //           return (
    //             <div className="fw_500" key={rData.idField}>
    //               <Radio
    //                 value={rData.idField}
    //                 checked={JSON.parse(rData.idField) == value}
    //               >
    //                 {rData.valueField}
    //               </Radio>
    //             </div>
    //           );
    //         })}
    //     </Radio.Group>
    //   </FormLabel>
    // </Col>
  );
}
